import React, { useState, useEffect } from "react";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import styled from "styled-components";
import Layout from "../components/Layout";

const Box = styled.div`
  display: grid;
  grid-template-columns: repeat(9, 1fr);
  grid-template-rows: repeat(3, 1fr);
  width: 100%;
  height: 100vh;
`;

const SponsorImgBox = styled.div`
  width: 100%;
  height: 100%;
  padding: 1em;
  max-width: 250px;
  max-height: 125px;
  background-color: white;
  position: relative;
  &:nth-of-type(1) {
    grid-column: 1 / 2;
    grid-row: 2 / 3;
    transform: translateX(5vw) translateY(10vh);
  }
  &:nth-of-type(2) {
    grid-column: 2 / 3;
    grid-row: 1 / 2;
    transform: translateX(3vw) translateY(7vh);
  }
  &:nth-of-type(3) {
    grid-column: 2 / 3;
    grid-row: 3 / -1;
    transform: translateX(0) translateY(5vh);
  }
  &:nth-of-type(4) {
    grid-column: 3 / 4;
    grid-row: 2 / 3;
    transform: translateX(4vw) translateY(-3vh);
  }
  &:nth-of-type(5) {
    grid-column: 4 / 5;
    grid-row: 1 / 2;
    transform: translateX(0) translateY(5vh);
  }
  &:nth-of-type(6) {
    grid-column: 5 / 6;
    grid-row: 3 / -1;
    transform: translateX(6vw) translateY(5vh);
  }
  &:nth-of-type(7) {
    grid-column: 6 / 7;
    grid-row: 2 / 3;
    transform: translateX(8vw) translateY(5vh);
  }
  &:nth-of-type(8) {
    grid-column: 7 / 8;
    grid-row: 1 / 2;
    transform: translateX(0) translateY(15vh);
  }
  &:nth-of-type(9) {
    grid-column: 8 / 9;
    grid-row: 3 / -1;
    transform: translateX(0) translateY(5vh);
  }
  &:nth-of-type(10) {
    grid-column: 9 / -1;
    grid-row: 1 / 2;
    transform: translateX(-7vw) translateY(6vh);
  }
`;

const SponsorImg = styled(Img)`
  width: 100%;
  height: 100%;
`;

const SponsorBox = ({ src }) => {
  return (
    <SponsorImgBox>
      <SponsorImg fluid={src} imgStyle={{ objectFit: `contain` }} />
    </SponsorImgBox>
  );
};

const Container = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: -1;
`;

const StyledImg = styled(Img)`
  height: 100%;
  width: 100%;
  object-fit: cover;
`;

const SponsorsPage = ({ data }) => {
  return (
    <Layout>
      <>
        <Container>
          <StyledImg fluid={data.rockCircle.fluid} />
        </Container>
        <Box>
          {data.pattison && <SponsorBox src={data.pattison.fluid} />}
          {data.oac && <SponsorBox src={data.oac.fluid} />}
          {data.canHeritage && <SponsorBox src={data.canHeritage.fluid} />}
          {data.TDReady && <SponsorBox src={data.TDReady.fluid} />}
          {data.canadaCouncil && <SponsorBox src={data.canadaCouncil.fluid} />}
          {data.cfa && <SponsorBox src={data.cfa.fluid} />}
          {data.telefilm && <SponsorBox src={data.telefilm.fluid} />}
          {data.tac && <SponsorBox src={data.tac.fluid} />}
          {data.imagesFestival && (
            <SponsorBox src={data.imagesFestival.fluid} />
          )}
          {data.dtlg && <SponsorBox src={data.dtlg.fluid} />}
        </Box>
      </>
    </Layout>
  );
};

export const query = graphql`
  query {
    rockCircle: imageSharp(fluid: { originalName: { eq: "rock-circle.png" } }) {
      id
      fluid {
        ...GatsbyImageSharpFluid
      }
    }
    tac: imageSharp(fluid: { originalName: { eq: "Logo_TAC_FCOT.png" } }) {
      id
      fluid {
        ...GatsbyImageSharpFluid
      }
    }
    oac: imageSharp(fluid: { originalName: { eq: "Logo_OAC.png" } }) {
      id
      fluid {
        ...GatsbyImageSharpFluid
      }
    }
    telefilm: imageSharp(
      fluid: { originalName: { eq: "Logo_Telefilm_Noir.png" } }
    ) {
      id
      fluid {
        ...GatsbyImageSharpFluid
      }
    }
    dtlg: imageSharp(fluid: { originalName: { eq: "Logo_DTLG_red.png" } }) {
      id
      fluid {
        ...GatsbyImageSharpFluid
      }
    }
    canHeritage: imageSharp(
      fluid: { originalName: { eq: "Logo_Can_Heritage.png" } }
    ) {
      id
      fluid {
        ...GatsbyImageSharpFluid
      }
    }
    canadaCouncil: imageSharp(
      fluid: { originalName: { eq: "Logo_Canada_Council.png" } }
    ) {
      id
      fluid {
        ...GatsbyImageSharpFluid
      }
    }
    cfa: imageSharp(fluid: { originalName: { eq: "Logo_CFA.png" } }) {
      id
      fluid {
        ...GatsbyImageSharpFluid
      }
    }
    pattison: imageSharp(
      fluid: { originalName: { eq: "Logo_PattisonOutdoor.png" } }
    ) {
      id
      fluid {
        ...GatsbyImageSharpFluid
      }
    }
    imagesFestival: imageSharp(
      fluid: { originalName: { eq: "Logo_ImagesFestival.png" } }
    ) {
      id
      fluid {
        ...GatsbyImageSharpFluid
      }
    }
    TDReady: imageSharp(
      fluid: { originalName: { eq: "Logo_TDReadyCommitment_Lockup.png" } }
    ) {
      id
      fluid {
        ...GatsbyImageSharpFluid
      }
    }
  }
`;

export default SponsorsPage;
